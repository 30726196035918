import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import Imagens from '../anexos/imagens';
import { SITUACAO_LAUDO } from './constantes';
import { temPermissaoRBAC, temPermissaoRede } from '../../secutity/acl';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import { PERMISSOES } from '../../common/Constants';
import { open, configure } from '../../reducers/alerta';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));

const DadosExame = (props) => {
    const { id, imprimirFn, imprimirProtocoloFn, imprimirTermoFn, voltarFn, ocultarBotoes = false } = props;

    const user = useSelector(state => state.index.user);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const EEG_API_BASE_URL = global.gConfig.url_base_eeg;
    const [exame, setExame] = useState(null);

    const [priorizar, setPriorizar] = useState(false);
    const [invalidar, setInvalidar] = useState(false);
    const [permissaoBtns, setPermissaoBtns] = useState([]);

    useEffect(() => {

        if (user.perfisRBAC && exame) {
            let rede;
            user.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                setPermissaoBtns([
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ]);
            }
        }
    }, [user, exame]);

    useEffect(() => {
        if (id) {
            axios
                .get(`${EEG_API_BASE_URL}/exame/${id}`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setExame(data);
                })
                .catch(err => {
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let titulo = strings.erro;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        titulo = data.message;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    const alertConfig = {
                        title: titulo,
                        message: msg,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    dispatch(open(false));
                                    voltarFn();
                                }
                            }
                        ],
                        onClose: () => {
                            dispatch(open(false));
                            voltarFn();
                        }
                    };
                    dispatch(configure(alertConfig));
                });
        }
    }, [id]);

    return (
        <SttContainer>
            {
                !exame
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{exame.exame.descricao_exame}</SttHeading>

                        <SttExpansionPanel
                            title={strings.paciente}
                            children={
                                <div>
                                    <SttTextItem title={strings.nome} content={exame.exame.nome_paciente} />
                                    <SttTextItem title={strings.dataNascimento} content={exame.exame.data_nascimento_paciente_formatada} />
                                    <SttTextItem title={strings.sexo} content={exame.exame.sexo_paciente} />
                                    {exame.indicacao?.peso_paciente && <SttTextItem title={strings.peso} content={`${exame.indicacao.peso_paciente} kg`} />}
                                    {exame.indicacao?.altura_paciente && <SttTextItem title={strings.altura} content={`${exame.indicacao.altura_paciente} cm`} />}
                                    <SttTextItem title={strings.idade} content={`${exame.exame.idade_paciente} anos`} />
                                    {exame.exame.cartao_sus_paciente && <SttTextItem title={strings.cns} content={exame.exame.cartao_sus_paciente} />}
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosExame}
                            children={
                                <div>
                                    <SttTextItem title={strings.modalidade} content={exame.exame.descricao_modalidade} />
                                    <SttTextItem title={strings.dataExame} content={`${exame.exame.data_exame} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem title={strings.protocolo} content={exame.exame.protocolo_rctm} />
                                    <SttTextItem title={strings.requisicao} content={exame.exame.requisicao} />
                                    <SttTextItem title={strings.instituicao} content={exame.exame.nome_instituicao} />
                                    <SttTextItem title={strings.medicoSolicitante} content={exame.exame.nome_medico_solicitante} />
                                    {exame.exame.nome_medico_executor && <SttTextItem title={strings.medicoExecutor} content={exame.exame.nome_medico_executor} />}
                                </div>
                            }
                        />

                        {
                            exame.indicacao &&
                            <SttExpansionPanel
                                title={strings.indicacaoClinica}
                                children={
                                    <div>
                                        {exame.indicacao.antededentes && <SttTextItem title={strings.antecedentes} content={exame.indicacao.antecedentes} />}
                                        {exame.indicacao.indicacao_exame && <SttTextItem title={strings.indicacaoExame} content={exame.indicacao.indicacao_exame} />}
                                        {exame.indicacao.historia_clinica && <SttTextItem title={strings.historiaClinica} content={exame.indicacao.historia_clinica} />}
                                        {exame.indicacao.medicamentos_em_uso && <SttTextItem title={strings.medicamentosEmUso} content={exame.indicacao.medicamentos_em_uso} />}
                                        {exame.indicacao.resultado_exames_anteriores && <SttTextItem title={strings.resultadosExamesAnteriores} content={exame.indicacao.resultado_exames_anteriores} />}
                                    </div>
                                }
                            />
                        }

                        {
                            exame.exame.observacao_tecnica?.trim() &&
                            <SttExpansionPanel
                                title={strings.outrasInformacoes}
                                children={
                                    <div>
                                        {exame.exame.observacao_tecnica && <SttTextItem title={strings.observacaoTecnica} content={exame.exame.observacao_tecnica} />}
                                    </div>
                                }
                            />
                        }

                        {
                            temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO) && exame.laudo &&
                            <SttExpansionPanel
                                title={strings.laudo}
                                children={
                                    <div dangerouslySetInnerHTML={{ __html: exame.laudo }}></div>
                                }
                            />
                        }
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    !exame.exame.valido &&
                                    <SttNotification severity="info" className={classes.notification}>
                                        <SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
                                        <div className={classes.notificationContent}>
                                            <span>{`${strings.motivo}: ${exame.exame.motivo_invalidacao}`}</span>
                                            <span>{`${strings.responsavel}: ${exame.exame.responsavel_invalidacao}`}</span>
                                            <span>{`${strings.data}: ${exame.exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
                                        </div>
                                    </SttNotification>
                                }
                                {
                                    temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_IMAGEM) &&
                                    <Imagens idExame={id} />
                                }
                            </SttGrid>
                        </SttGrid>


                        {
                            exame.exame.valido && !ocultarBotoes &&
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            imprimirFn(id)
                                        }}
                                        nomarginleft="true"
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirProtocoloFn({ ...exame.exame, id: exame.exame.id_exame })
                                        }}
                                    >
                                        {strings.protocolo}
                                    </SttButton>
                                    {
                                        (permissaoBtns[1] ||
                                            (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                                        exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setPriorizar(true)}
                                        >
                                            {strings.priorizar}
                                        </SttButton>
                                    }
                                    {
                                        (((permissaoBtns[0] ||
                                            (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                            exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                            (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                                exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                                        exame.exame.valido &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setInvalidar(true)}
                                        >
                                            {strings.invalidar}
                                        </SttButton>
                                    }
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirTermoFn(exame.exame)
                                        }}
                                    >
                                        {strings.imprimirTermo}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        }

                        {
                            (permissaoBtns[1] ||
                                (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                            priorizar && exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && exame.exame.valido &&
                            <Priorizar priorizar={priorizar} setPriorizar={setPriorizar} idExame={id} idInstituicao={exame.exame.id_instituicao} callback={voltarFn} />
                        }
                        {
                            (((permissaoBtns[0] ||
                                (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                    exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                            invalidar &&
                            exame.exame.valido &&
                            <Invalidar
                                invalidar={invalidar}
                                setInvalidar={setInvalidar}
                                idExame={id}
                                idInstituicao={exame.exame.id_instituicao}
                                idModalidade={exame.exame.id_modalidade}
                                callback={voltarFn} 
                            />
                        }
                    </>
            }
        </SttContainer>
    )

}

export default DadosExame;