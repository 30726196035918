import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttLoading, SttTranslateHook, SttTabs } from '@stt-componentes/core';
import { useMoment } from '../../hooks';
import { open, configure } from '../../reducers/alerta';
import HistoricoPaciente from './historicoPaciente';
import Container from './container';

const useStyles = makeStyles((theme) => ({
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	},
	tabs: {
		padding: 0
	}
}));

const Index = ({ idExame, idRede, callbackProximoExame, callbackExameCarregado, notificar, setLaudar = () => {} }) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const dispatch = useDispatch();

	const EEG_API_BASE_URL = global.gConfig.url_base_eeg;

	const location = useLocation();
	const moment = useMoment();
	const classes = useStyles();
	const [exame, setExame] = useState(null);
	const [abas, setAbas] = useState([]);
	const [abaAtiva, setAbaAtiva] = useState('1');
	const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
	const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

	const handleCloseAlerta = () => {
		if (contextoFabricalaudo) {
			callbackProximoExame();
		} else {
			setLaudar(false);
		}
	};

	useEffect(() => {
		if (idExame) {
			axios
				.get(`${EEG_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						let { data } = response.data;
						let dataMoment = moment(data.exame.data_nascimento_paciente);
						if (dataMoment.isValid()) {
							var now = moment();
							var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
							var idade = moment.duration(now.diff(dataMomentFormatada));
							var anos = idade.years();
							var meses = idade.months();
							var dias = idade.days();
							data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`;
						}
						setExame(data);
						if (contextoFabricalaudo) {
							callbackExameCarregado();
						}
					}
				})
				.catch((err) => {
					console.log(err);
					const { response } = err;
					let msg = strings.erroDesconhecido;
					let arrMensagem = [];
					if (response) {
						const { data } = response;
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
					}

					const alertConfig = {
						title: strings.erro,
						message: msg,
						type: 'error',
						open: true,
						options: [
							{
								title: strings.ok,
								onClick: () => {
									dispatch(open(false));
									handleCloseAlerta();
								}
							}
						],
						onClose: () => {
							dispatch(open(false));
							handleCloseAlerta();
						}
					};
					dispatch(configure(alertConfig));
				});
		}

		return () => {
			setLaudar(false);
			setExame(null);
		};
	}, []);

	useEffect(() => {
		if (exame) {
			axios
				.get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						if (response.data.data.length > 0) {
							let dadosAbas = [];

							// Aba histórico
							dadosAbas.push({
								historico: response.data.data,
								titulo: strings.historico,
								permanente: true,
								setAbas,
								setAbaAtiva,
								conteudo: HistoricoPaciente
							});

							// Aba laudo
							dadosAbas.push({
								exame: exame,
								idRede,
								callbackProximoExame,
								callbackExameCarregado,
								notificar,
								permanente: true,
								setLaudar: setLaudar,
								titulo: strings.laudo,
								conteudo: Container
							});

							setAbas(dadosAbas);
						}
					}
				})
				.catch((err) => console.log(err));
		}
	}, [exame, setAbas]);

	return (
		<>
			<div className={classes.carregando}>
				<SttLoading
					open={!exame && !contextoFabricalaudo}
					text={strings.carregandoExame}
				/>
			</div>
			{exame &&
				((abas.length > 0 && (
					<SttTabs
						className={classes.tabs}
						abaAtiva={abaAtiva}
						setAbaAtiva={setAbaAtiva}
						setAbas={setAbas}
						abas={abas}
						permanente={true}
						canClose={true}
						handleCloseTab={(indice) => {
							abas.splice(indice, 1);
							setAbaAtiva(abas.length - 1 + '');
						}}
					/>
				)) || (
					<Container
						exame={exame}
						idRede={idRede}
						callbackProximoExame={callbackProximoExame}
						callbackExameCarregado={callbackExameCarregado}
						setLaudar={setLaudar}
						notificar={notificar}
					/>
				))}
		</>
	);
};

Index.propTypes = {
	idExame: PropTypes.number.isRequired,
	callbackProximoExame: PropTypes.func,
	callbackExameCarregado: PropTypes.func
};

export default Index;
