import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Visualizacao from './index';
import Utils from '../../utils';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { ...other } = props;
    const classes = useStyles();

    const [gerandoPdf, setGerandoPdf] = useState(false);

    const imprimir = (idExame) => {
        setGerandoPdf(true);
        Utils.imprimirFn({ id: idExame }, () => setGerandoPdf(false));
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirProtocoloFn(exame, () => setGerandoPdf(false));
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirTcleFn({ id: exame.id_paciente }, () => setGerandoPdf(false));
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

export default Aba;