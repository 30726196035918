import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import StickyBox from 'react-sticky-box';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttButton, SttHidden, SttLoading, SttTranslateHook } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from '../anexos/imagens';
import Invalidar from '../exame/invalidar';
import { open, configure } from '../../reducers/alerta';

const useStyles = makeStyles((theme) => ({
	wrapperBotao: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	modalBody: {
		overflow: 'hidden'
	},
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	}
}));

const Container = ({ exame, idRede, callbackProximoExame, notificar, setLaudar = () => {} }) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const dispatch = useDispatch();

	const EEG_API_BASE_URL = global.gConfig.url_base_eeg;

	const location = useLocation();
	const classes = useStyles();
	const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
	const [imagensAtualizadas, setImagensAtualizadas] = useState(false);

	// Invalidação do exame
	const [invalidar, setInvalidar] = useState(false);

	const handleCloseAlerta = () => {
		if (contextoFabricalaudo) {
			callbackProximoExame();
		} else {
			setLaudar(false);
		}
	};

	const voltar = () => {
		// Cancela emissão do laudo
		cancelarEmissao();

		// Controle de componentes. Volta para tela anterior
		setLaudar(false);
	};

	const cancelarEmissao = () => {
		axios
			.post(`${EEG_API_BASE_URL}/laudo/cancelar`, { idExame: exame.exame.id_exame }, { headers: getHeaders() })
			.catch((err) => console.log(err));
	};

	const ignorarExame = () => {
		notificar(strings.ignorandoExame);
		// Insere exame ignorado
		axios
			.post(`${EEG_API_BASE_URL}/laudo/ignorar-exame`, { idExame: exame.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
			.then((resposta) => {
				// Cancela emissão do laudo
				cancelarEmissao();
				// Carrega o próximo exame
				callbackProximoExame();
			})
			.catch((err) => {
				console.log(err);
				const alertConfig = {
					title: strings.erro,
					message: strings.erroIgnorarExame,
					type: 'error',
					open: true,
					options: [
						{
							title: strings.ok,
							onClick: () => {
								dispatch(open(false));
								handleCloseAlerta();
							}
						}
					],
					onClose: () => {
						dispatch(open(false));
						handleCloseAlerta();
					}
				};
				dispatch(configure(alertConfig));
			});
	};

	useEffect(() => {
		if (!imagensAtualizadas) {
			setImagensAtualizadas(true);
		}
	}, [imagensAtualizadas]);

	return (
		<>
			<div className={classes.carregando}>
				<SttLoading
					open={!exame && !contextoFabricalaudo}
					text={strings.carregandoExame}
				/>
			</div>
			{exame && (
				<>
					<SttGrid
						container
						spacing={3}
					>
						<SttGrid
							item
							xs={12}
							className={classes.wrapperBotao}
						>
							{
								<SttButton
									variant="contained"
									danger={+true}
									onClick={() => setInvalidar(true)}
								>
									{strings.invalidar}
								</SttButton>
							}

							{!contextoFabricalaudo && (
								<SttButton
									type="button"
									variant="outlined"
									color="primary"
									onClick={voltar}
								>
									{strings.voltar}
								</SttButton>
							)}

							{contextoFabricalaudo && (
								<SttButton
									type="button"
									variant="outlined"
									color="primary"
									onClick={ignorarExame}
								>
									{strings.proximoExame}
								</SttButton>
							)}
						</SttGrid>
					</SttGrid>
					<SttGrid
						container
						spacing={3}
					>
						<SttHidden mdUp>
							<SttGrid
								item
								xs={12}
							>
								{imagensAtualizadas && <Imagens idExame={exame.exame.id_exame} />}
							</SttGrid>
							<SttGrid
								item
								xs={12}
							>
								<Laudo
									dados={exame}
									callbackProximoExame={callbackProximoExame}
									setImagensAtualizadas={setImagensAtualizadas}
									setLaudar={setLaudar}
								/>
							</SttGrid>
						</SttHidden>
						<SttHidden only={['xs', 'sm']}>
							<SttGrid
								item
								xs={6}
							>
								<StickyBox>{imagensAtualizadas && <Imagens idExame={exame.exame.id_exame} />}</StickyBox>
							</SttGrid>
							<SttGrid
								item
								xs={6}
							>
								<Laudo
									dados={exame}
									callbackProximoExame={callbackProximoExame}
									setImagensAtualizadas={setImagensAtualizadas}
									setLaudar={setLaudar}
								/>
							</SttGrid>
						</SttHidden>
					</SttGrid>
				</>
			)}
			{invalidar && (
				<Invalidar
					invalidar={invalidar}
					setInvalidar={setInvalidar}
					idExame={exame.exame.id_exame}
					idRede={idRede}
					callback={callbackProximoExame}
				/>
			)}
		</>
	);
};

Container.propTypes = {
	callbackProximoExame: PropTypes.func,
	callbackExameCarregado: PropTypes.func
};

export default Container;
